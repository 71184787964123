import Service from "./service";
import _ from "lodash";
import {OrderedMap} from "immutable";

export default class Store {
    constructor(app) {
        this.app = app;
        this.service = new Service(this);
        this.token = this.loadTokenFromLocalStorage();
        this.cameras = new OrderedMap();
        this.users = new OrderedMap();
        this.movieList = new OrderedMap();
        this.personMovieList = new OrderedMap();
        this.carImageList = new OrderedMap();
        this.sensorData = new OrderedMap();
        this.chartData = new OrderedMap();
        this.countData = new OrderedMap();
        this.regionData = new OrderedMap();
        this.noticeData = new OrderedMap();

        this.isFetched = {
            userCamera: false,
            userList: false,
            userMovieList: false,
            personMovieList: false,
            carImageList: false,
            sensorData: false,
            streamCamera: false,
            stopCamera: false,
            chartData: false,
            regionData: false,
            noticeData : false,
        };
    }

    addCameraToCache(id, cam, reload = false) {
        this.cameras = this.cameras.set(id, cam);
        if (reload) {
            this.update();
        }
    }
    addNoticeToCahce(id, notice, reload = false){
        this.noticeData = this.noticeData.set(id,notice);
        if(reload){
            this.update();
        }
    }

    addUserToCache(id, user, reload = false) {
        this.users = this.users.set(id, user);
        if (reload) {
            this.update();
        }
    }

    addUserMovieToCache(id, movie, reload = false) {
        this.movieList = this.movieList.set(id, movie);
        if (reload) {
            this.update();
        }
    }

    addCarImageToCache(id, movie, reload = false) {
        this.carImageList = this.carImageList.set(id, movie);
        if (reload) {
            this.update();
        }
    }

    addPersonMovieToCache(id, movie, reload = false) {
        this.personMovieList = this.personMovieList.set(id, movie);
        if (reload) {
            this.update();
        }
    }

    addSensorDataToCache(id, movie, reload = false) {
        this.sensorData = this.sensorData.set(id, movie);
        if (reload) {
            this.update();
        }
    }

    addChartDataToCache(id, movie, reload = false) {
        this.chartData = this.chartData.set(id, movie);
        if (reload) {
            this.update();
        }
    }

    addCountDataToCache(id, movie, reload = false) {
        this.countData = this.countData.set(id, movie);
        if (reload) {
            this.update();
        }
    }

    addRegionDataToCache(id, region, reload = false) {
        this.regionData = this.regionData.set(id, region);
        if (reload) {
            this.update();
        }
    }


    addCamera(camera, cb = () => {
    }) {
        this.service
            .post("/api/me/cameras", camera)
            .then(response => {
                const camera = response.data;
                const id = _.get(camera, "_id");
                this.addCameraToCache(id, camera, true);
                return cb(null, camera);
            })
            .catch(err => {
                return cb(err, null);
            });
    }

    loadTokenFromLocalStorage() {
        const data = localStorage.getItem("token");
        let token = null;

        try {
            token = JSON.parse(data);
        } catch (err) {
            console.log("Unable decode token from local store with error:", err);
        }
        return token;
    }

    saveUserTokenToLocalStorage(tokenObject) {
        this.token = tokenObject;
        const stringData = JSON.stringify(tokenObject);
        localStorage.setItem("token", stringData);
        // let update our component
        this.update();
    }

    createUserAccount(user, cb = () => {
    }) {
        this.service
            .post("/api/users", user)
            .then(response => {
                return cb(null, response.data);
            })
            .catch(err => {
                console.log("Create new account with error:", err);
                return cb(err, null);
            });
    }

    signOut() {
        const tokenId = _.get(this.token, "_id");
        this.service.post("/api/me/logout", {tokenId: tokenId});
        localStorage.removeItem("me");
        localStorage.removeItem("token");
        this.user = null;
        this.token = null;
        this.cameras = new OrderedMap();
        this.users = new OrderedMap();
        this.movieList = new OrderedMap();
        this.personMovieList = new OrderedMap();
        this.carImageList = new OrderedMap();
        this.sensorData = new OrderedMap();
        this.chartData = new OrderedMap();
        this.countData = new OrderedMap();
        this.update();
    }

    login(user, cb = () => {
    }) {
        this.service
            .post("/api/users/login", user)
            .then(response => {
                const token = _.get(response, "data", null);

                this.saveUserTokenToLocalStorage(token);

                return cb(null, token);
            })
            .catch(err => {
                return cb(err, null);
            });
    }

    getCount() {
        // now fetch all camera from server
        this.service
            .post("/api/count/uncheck")
            .then(response => {
                const uncheck = response.data;
                if (uncheck.length) {
                    const movieCount = _.get(_.find(uncheck, 'movie'), 'movie', 0);
                    this.addCountDataToCache('movie', {"movie": movieCount});
                    const personCount = _.get(_.find(uncheck, 'person'), 'person', 0);
                    this.addCountDataToCache('person', {"person": personCount});
                    const carCount = _.get(_.find(uncheck, 'car'), 'car', 0);
                    this.addCountDataToCache('car', {"car": carCount});
                }
            })
            .catch(err => {
                console.log("An able loading camera with error", err);
            });
        if (this.countData !== null)
            return this.countData.valueSeq();
    }

    getUserCameras(callback) {
        if (!this.isFetched.userCamera) {
            this.isFetched.userCamera = true;
            // now fetch all camera from server
            this.service
                .get("/api/me/cameras")
                .then(response => {
                    const cameras = response.data;
                    if (cameras.length) {
                        _.each(cameras, cam => {
                            const id = _.get(cam, "_id");
                            this.addCameraToCache(id, cam, false);
                        });
                        //this.update();
                    }
                })
                .then(() => {
                    if (this.cameras !== null) {
                        callback(this.cameras.valueSeq().toArray());
                        //return this.regionData.valueSeq();
                    }
                })
                .catch(err => {
                    console.log("An able loading camera with error", err);
                });
        }
        this.isFetched.userCamera = false;
    }

    getUserOnceCameras(callback) {
        if (!this.isFetched.userCamera) {
            this.isFetched.userCamera = true;
            // now fetch all camera from server
            this.service
                .get("/api/me/cameras")
                .then(response => {
                    const cameras = response.data;
                    if (cameras.length) {
                        _.each(cameras, cam => {
                            const id = _.get(cam, "_id");
                            this.addCameraToCache(id, cam, false);
                        });

                        //this.update();
                    }
                })
                .then(() => {
                    if (this.cameras !== null) {
                        callback(this.cameras.valueSeq().toArray());
                        //return this.regionData.valueSeq();
                    }
                })
                .catch(err => {
                    console.log("An able loading camera with error", err);
                });
        }
        this.isFetched.userCamera = false;
    }

    getUserChartData(callback) {
        if (!this.isFetched.chartData) {
            // now fetch all camera from server
            this.service
                .post("/api/date/movie")
                .then(response => {
                    const chartData = response.data;
                    if (chartData.length) {
                        _.each(chartData, chart => {
                            const id = _.get(chart, "_id");
                            this.addChartDataToCache(id, chart, false);
                        });
                    }
                })
                .then(() => {
                    if (this.chartData !== null) {
                        callback(this.chartData.valueSeq().toArray());
                        //return this.regionData.valueSeq();
                    }
                })
                .catch(err => {
                    console.log("An able loading camera with error", err);
                });
        }
    }

    getRegionData(callback) {
        if (!this.isFetched.regionData) {
            // now feth all camera from server
            this.service.get("/api/get/regions")
                .then(response => {
                    const regionData = response.data;
                    if (regionData.length) {
                        _.each(regionData, region => {
                            const id = _.get(region, "_id");
                            this.addRegionDataToCache(id, region, false);
                        });
                    }
                })
                .then(() => {
                    if (this.regionData !== null) {
                        callback(this.regionData.valueSeq().toArray());
                        //return this.regionData.valueSeq();
                    }
                })
                .catch(err => {
                    console.log("An able loading region data with error", err);
                });
        }
    }
    getAlluserApptoken(callback){
        this.service.get("/api/notify/update")
            .then( response => {
                const apptokenAll = response.data;
                if(apptokenAll.length){
                    callback(apptokenAll);
                }
            })
    }

    getNoticeData(callback) {
        if (!this.isFetched.noticeData) {
            this.isFetched.noticeData = true;
            // now feth all camera from server
            this.service.get("/api/get/notices")
                .then(response => {
                    const noticeDatas = response.data;
                    if (noticeDatas.length) {
                        _.each(noticeDatas, notice => {
                            const id = _.get(notice, "_id");
                            this.addNoticeToCahce(id, notice, false);
                        });
                    }
                })
                .then(() => {
                    if (this.noticeData !== null) {
                        callback(this.noticeData.valueSeq().toArray());
                    }
                })
                .catch(err => {
                    console.log("An able loading notice data with error", err);
                });
        }
    }
    newRegion(regionNumber, city, region, callback){
        const option = {
            city,
            region,
            regionNumber
        };
        this.service.post('/api/create/region', option)
            .then(response =>{
                const regionDatas = response.data;
                if (regionDatas.length) {
                    _.each(regionDatas, region => {
                        const id = _.get(region, "_id");
                        this.addRegionDataToCache(id, region, false);
                    });
                }
                callback(regionDatas);
            })
            .catch( err => {
                console.log("An able loading notice data with error", err);
            });
    }

    newNotice(noticeTitle, noticeContent, callback){
        const option = {
            title: noticeTitle,
            content: noticeContent,
        };
        this.service.post("/api/create/notice", option)
            .then(response => {
                const newNotice = response.data;
                if (newNotice) {
                    const id = _.get(newNotice, "_id");
                    this.addNoticeToCahce(id, newNotice, false);
                    callback(newNotice);
                }
            })
            .catch(err => {
                console.log("An able loading notice data with error", err);
            });
    }

    removeNotice(noticeId) {
        if (!noticeId ||noticeId.length !== 0) {
            const option = {
                _id : noticeId,
            };
            this.service.post("/api/remove/notice", option);
            this.noticeData = this.noticeData.remove(noticeId);
        }
    }

    removeRegion(regionId) {
        if (!regionId ||regionId.length !== 0) {
            const option = {
                _id : regionId,
            };
            this.service.post("/api/remove/region", option);
            this.regionData = this.regionData.remove(regionId);
        }
    }

    updateNotice(notice) {
        const noticeId = _.get(notice, "_id");
        if (!noticeId ||noticeId.length !== 0) {
            const title = _.get(notice, "title").toString();
            const content = _.get(notice, "content").toString();
            const option = {
                _id: noticeId,
                title: title,
                content: content,
            };
            this.service.post("/api/update/notice", option);
        }
    }

    updateRegion(region){
        const regionId = _.get(region,'_id');
        if(!regionId || regionId.length !== 0){
            const city = _.get(region, 'city').toString();
            const regionName = _.get(region,'region').toString();
            const region_number = _.get(region,'region_number').toString();
            const option = {
                _id : regionId,
                city : city,
                region : regionName,
                region_number : region_number
            };
            this.service.post("/api/update/region", option);
        }
    }

    removeUserCamera(cameraId) {
        if (cameraId.length !== 0) {
            const option = {
                headers: {
                    _id: cameraId
                }
            };
            this.service.get("/api/remove/camera", option);
            this.cameras = this.cameras.remove(cameraId);
        }
    }

    updateUserCamera(cam) {
        const cameraId = _.get(cam, "_id");
        console.log("cam",cam);
        if (cameraId.length !== 0) {
            const name = _.get(cam, "name").toString();
            const phone = _.get(cam, "phone");
            const cameraIp = _.get(cam, "cameraIp").toString();
            const httpPort = _.get(cam, "httpPort") || "";
            const macAddress = _.get(cam, "macAddress") || "";
            console.log("httpPort", httpPort)
            const option = {
                _id: cameraId,
                name: name,
                phone: phone,
                cameraIp: cameraIp,
                httpPort: httpPort,
                macAddress: macAddress
            };
            console.log("updateUserCamera", option)
            this.service.post("/api/update/camera", option);
        }
    }

    getUserList(callback) {
        if (!this.isFetched.userList) {
            this.service
                .get("/api/me/users")
                .then(response => {
                    const users = response.data;
                    if (users.length) {
                        _.each(users, user => {
                            const id = _.get(user, "_id");
                            this.addUserToCache(id, user, false);
                        });
                    }
                })
                .then(() => {
                    if (this.users !== null) {
                        callback(this.users.valueSeq().toArray());
                    }
                })
                .catch(err => {
                    console.log("An able loading user with error", err);
                });
        }
    }


    removeUserInformation(userId) {
        if (userId.length !== 0) {
            const option = {
                headers: {
                    _id: userId
                }
            };
            this.service.get("/api/remove/user", option);
            this.users = this.users.remove(userId);
        }
    }

    updateUserInformation(user) {
        const userId = _.get(user, "_id");
        if (userId.length !== 0) {
            const name = _.get(user, "name").toString();
            const phone = _.get(user, "phone").toString();
            const region = _.get(user, "region").toString();
            const sensor = _.get(user, "sensor").toString();

            const option = {
                _id: userId,
                name: name,
                phone: phone,
                region: region,
                sensor: sensor
            };
            this.service.post("/api/update/user", option);
        }
    }

    getUserMovieList(callback) {
        if (!this.isFetched.userMovieList) {
            this.service
                .get("/api/me/movies")
                .then(response => {
                    const movies = response.data;

                    if (movies.length) {
                        _.each(movies, movie => {
                            const id = _.get(movie, "_id");
                            this.addUserMovieToCache(id, movie, false);
                        });

                        this.update();
                    }
                }).then(() => {
                if (this.movieList !== null) {
                    callback(this.movieList.valueSeq().toArray());
                }
            })
                .catch(err => {
                    console.log("An able loading user with error", err);
                });
        }
    }

    getPersonMovieList(callback) {
        if (!this.isFetched.personMovieList) {
            this.service
                .get("/api/get/persons")
                .then(response => {
                    const movies = response.data;

                    if (movies.length) {
                        _.each(movies, movie => {
                            const id = _.get(movie, "_id");
                            this.addPersonMovieToCache(id, movie, false);
                        });

                        this.update();
                    }
                })
                .then(() => {
                    if (this.personMovieList !== null) {
                        callback(this.personMovieList.valueSeq().toArray());
                    }
                })
                .catch(err => {
                    console.log("An able loading user with error", err);
                });
        }
    }

    getCarImageList() {
        if (!this.isFetched.carImageList) {
            this.isFetched.carImageList = true;

            this.service
                .get("/api/get/cars")
                .then(response => {
                    const carImages = response.data;

                    if (carImages.length) {
                        _.each(carImages, carImage => {
                            const id = _.get(carImage, "_id");
                            this.addCarImageToCache(id, carImage, false);
                        });

                        this.update();
                    }
                })
                .catch(err => {
                    console.log("An able loading user with error", err);
                });
        }
        if (this.carImageList !== null)
            return this.carImageList.valueSeq();
    }

    getSensorData(sensorId, callback) {
        if (!this.isFetched.sensorData) {
            // now fetch get sensorData
            this.service
                .post("/api/find/sensor", sensorId)
                .then(response => {
                    const sensorData = response.data;
                    if (sensorData.length) {
                        const id = _.get(sensorData, "_id");
                        this.addSensorDataToCache(id, sensorData, false);
                    }
                }).then(() => {
                if (this.sensorData !== null) {
                    callback(_.get(this.sensorData.valueSeq().toArray()[0], '0', ''));
                }
            })
                .catch(err => {
                    console.log("An able loading sensorData with error", err);
                    this.isFetched.sensorData = true;
                });
        }
    }
    changePassword(userId, password){
        const changeUser = {
            userId : userId,
            password : password
        };
        this.service
            .post("/api/change/password", changeUser)
    }

    getCurrentUser(callback) {
        callback(_.get(this.token, "user"));
    }

    getCurrentUserTokenId() {
        return _.get(this.token, "_id");
    }

    update() {
        this.app.forceUpdate();
    }
}
