import React, {Component} from 'react'
import styled from 'styled-components'
import _ from 'lodash';
import {history} from "../../../history";
import screenfull from "screenfull";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import "./personMovieList.css";


const PersonVideoWrapper = styled.div`
  
`
const PersonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    z-index: 0;
    background: transparent;
`;

const PersonCard = styled.div`
        width : 45%;
        min-width: 300px;
        margin: 30px auto;
        border-radius: 15px;
        background: #f9f8fd;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        box-sizing: border-box;
        @media only screen and (max-width: 900px){
        width : 95%
        }
`;

export default class PersonMovieList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            movies: [],
            items: [],
            pager: {},
            printPage: 10,
        };
        this._onClickFullscreen = this._onClickFullscreen.bind(this);
        this._updateWindowDimensions = this._updateWindowDimensions.bind(this);
    }

    _zoomEventHandle(event){
        const target = event.target;
        const style = target.style;
        const targetWidth = style.width;
        let scale =  parseInt(targetWidth.substr(0,3))/100;
        if(isNaN(scale)){
           scale = 1;
        }
        const scalechange = 0.05;
        if(event.deltaY < 0){
            // zoom in
            scale = scale + scalechange;
        } else {
            // zoom out
            scale = scale - scalechange;
        }
        if(scale < 1){
            scale = 1;
        }
        const offsetX = -(event.x * (scale -1));
        const offsetY = -(event.y * (scale -1));
        style.width = String(scale* 100) + "%";
        style.height = String(scale * 100) + "%";
        style.transform = `translate(${offsetX}px,${offsetY}px)`;
        event.preventDefault();
    }
    _changeFull = (event) => {
        const target = event.target;
        const style = target.style;
        const reactPlayer = event.target.parentElement;
        if(reactPlayer.classList.contains('fullPlayer')){
            style.width = "100%";
            style.height = "100%";
            style.transform = 'translate(0px,0px)';
            reactPlayer.removeEventListener('mousewheel',this._zoomEventHandle);
            reactPlayer.classList.remove('fullPlayer');
            reactPlayer.classList.add('reactPlayer');
        } else {
            reactPlayer.addEventListener('mousewheel',this._zoomEventHandle);
            reactPlayer.classList.add('fullPlayer');
            reactPlayer.classList.remove('reactPlayer');
        }
    }

    _onClickFullscreen = (event) => {
        const target = event.target;
        screenfull.toggle(target.parentElement);
        const style = target.style;
        if (screenfull.enabled) {
            screenfull.on('change', () => {
                if(screenfull.isFullscreen){
                    target.addEventListener('mousewheel',this._zoomEventHandle);
                } else {
                    style.width = "100%";
                    style.height = "100%";
                    style.transform = `translate(0px,0px)`;
                    target.removeEventListener('mousewheel',this._zoomEventHandle);
                }
            });
        }
        };

    componentWillMount() {
        const {store} = this.props;
        this._updateWindowDimensions();
        window.addEventListener('resize', this._updateWindowDimensions);
        this._loadCurrentUser(store);
        this._loadUserMovies(store);
    }

    _loadCurrentUser(store) {
        store.getCurrentUser((res) => {
            this._setCurrentUser(res);
        });
    }

    _setCurrentUser(res) {
        const userID = _.get(res, '_id', '');
        if (!userID) {
            history.push('/login');
        } else {
            this.timerID2 = setInterval(() => this.props.updateData(), 1000);

        }
    }

    _updateWindowDimensions() {
        const {pager} = this.state;
        if (window.innerWidth > 850) {
            this.setState({printPage: 10});
        } else if (window.innerWidth > 750) {
            this.setState({printPage: 8});
        } else {
            this.setState({printPage: 6});
        }
        if (_.isEmpty(pager)) {
            return;
        }
        this._setPage(pager.currentPage);
    }

    _loadUserMovies(store) {
        store.getPersonMovieList((res) => {
            console.log('getPersonMovieList res = ', res);
            this.setState({movies: res}, () => this._setPage(1));
        });
    }

    _setPage(page) {
        let {pager} = this.state;
        const movieSize = this.state.movies.length;
        if (_.isEmpty(pager)) {
            pager = this._getPager(movieSize, 1, 4);
            const items = this.state.movies.slice(pager.startIndex, pager.endIndex + 1);
            this.setState({items, pager});
            return;
        }
        if (page < 1 || page > pager.totalPages) {
            return;
        }
        pager = this._getPager(movieSize, page, 4);
        const items = this.state.movies.slice(pager.startIndex, pager.endIndex + 1);
        this.setState({items, pager});
    }

    _getPager(totalItems, currentPage, pageSize) {
        const totalPages = Math.ceil(totalItems / pageSize);
        const {printPage} = this.state;
        let startPage, endPage;
        if (totalPages <= printPage) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= (printPage / 2 + 1)) {
                startPage = 1;
                endPage = printPage;
            } else if (currentPage + printPage / 2 - 1 >= totalPages) {
                startPage = totalPages - printPage + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - printPage / 2;
                endPage = currentPage + printPage / 2 - 1;
            }
        }
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        const pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    componentWillUnmount() {
        clearInterval(this.timerID2);
    }

    render() {
        const {items} = this.state;
        const {pager} = this.state;
        return <PersonVideoWrapper>
            <PersonWrapper className={"personWrapper"}>
                {!items || items.length === 0 ? null : items.map((movie, index) => {
                    const moviePath =
                        `${window.location.origin}/person/` +
                        _.get(movie, "phone").toString() +
                        "/" +
                        _.get(movie, "name").toString();
                    const Date = _.get(movie, 'created').toString();
                    return (
                        <PersonCard className={"personCard"}
                                    ref={c => {
                                        this.player = c;
                                    }}
                        >
                            <div className={"personContent"}>
                                <img
                                    alt={_.get(movie, 'name', '')}
                                    onClick={this._changeFull}
                                    src={moviePath}/>
                                <div className={"personTitle"}>
                                    <p>
                                        {_.get(movie, 'name').toString().substr(12)}
                                    </p>
                                </div>
                                <div className={"personDate"}>
                                    <p>
                                        물체 감지 시간 {Date}
                                    </p>
                                </div>
                            </div>
                        </PersonCard>
                    )
                })}
            </PersonWrapper>
            <div className={"moviePage"}>
                <ul className={"pagination"}>
                    <LeftIcon className={pager.currentPage === 1 ? 'disabled' : 'direction'}
                              onClick={() => this._setPage(pager.currentPage - 1)}>
                    </LeftIcon>
                    {!pager.pages || pager.pages.length < 1 ? null : pager.pages.map((page, index) =>
                        <li key={index} className={pager.currentPage === page ? 'active' : ''}>
                            <button onClick={() => this._setPage(page)}>{page}</button>
                        </li>
                    )}
                    <RightIcon
                        onClick={() => this._setPage(pager.currentPage + 1)}
                        className={pager.currentPage === pager.totalPages ? 'disabled' : 'direction'}>
                    </RightIcon>
                </ul>
            </div>
        </PersonVideoWrapper>
    }
}
