// import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
//import "./css/app.css";
import * as serviceWorker from "./serviceWorker";
import App from "./components/app";
import { Router } from "react-router-dom";
import { history } from "./history";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    typography: {
        fontFamily: '"Spoqa Han Sans"'
    }
});
alert('1')

ReactDOM.render(
    <Router history={history}>
        <MuiThemeProvider theme={theme}>
        <App />
        </MuiThemeProvider>
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
